// constants
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

function initWeb3(provider) {
  const web3 = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

export const connect = ({
  getContractNft,
  nftAbi,
  nftAddress,
  requiredChainId,
  requiredChainIdName,
  requiredChainIdNum,
}) => {
  return async (dispatch) => {
    dispatch(connectRequest());

    const infuraId = "2bdbc6824bc94be98732ff21ffaa53bc";
    const coinBase = {
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "walletconnect",
          infuraId: infuraId,
        },
      },
    };
    const providerOptions = {
      ...coinBase,
      walletconnect: {
        package: WalletConnect,
        options: {
          infuraId: infuraId, // required,
        },
      },
    };

    const web3Modal = new Web3Modal({
      // network: requiredChainIdName, // optional
      cacheProvider: false, // optional
      providerOptions, // required
    });

    const provider = await web3Modal.connect();

    await provider.enable();

    if (!provider) {
      dispatch(connectFailed("Something went wrong."));
    }

    // if (Web3.givenProvider == null) {
    //   dispatch(
    //     connectFailed(
    //       "Provider is null. Do you have a crypto wallet installed?"
    //     )
    //   );
    //   return;
    // }

    //const web3 = new Web3(Web3.givenProvider);
    const web3 = initWeb3(provider);
    const chainId = web3.currentProvider.chainId;

    if (chainId != requiredChainId) {
      dispatch(
        connectFailed(
          "Wallet is not connected to " + requiredChainIdName + " network"
        )
      );
      return;
    }

    const SmartContractObj = new web3.eth.Contract(nftAbi, nftAddress);
    const nft = getContractNft(web3);

    const ethereum = window.ethereum || provider;

    try {
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      const networkId = await web3.eth.net.getId();
      const accountId = accounts[0]; //"0x0e2925b0d8624f00adf1901932d5550d43012a7b"; //accounts[0];

      let transactions = [];
      let ownerTokens = 0;

      try {
        ownerTokens = await nft.methods.balanceOf(accountId).call();
        await SmartContractObj.getPastEvents(
          "Transfer",
          {
            fromBlock: 0,
            toBlock: "latest",
            filter: { to: accountId },
          },
          (error, events) => {
            if (!error) {
              events.forEach((event) => {
                const t = {
                  txnHash: event.transactionHash,
                  tokenId: event.returnValues.tokenId,
                  to: event.returnValues.to,
                  id: event.id,
                };

                transactions.push(t);
              });
              // for (var i = 0; i < events.length; i++) {
              //   transactions.push(events[i]);
              //   console.log(
              //     events[i].returnValues,
              //     events[i].returnValues.tokenId
              //   );
              // }
            }
          }
        );
      } catch (error) {}

      dispatch(
        connectSuccess({
          account: accountId,
          smartContract: SmartContractObj,
          web3: web3,
          connector: provider,
          type: provider.wc ? "wc" : "metamask",
          ownerTokens: ownerTokens,
          transactions: transactions,
        })
      );

      try {
        // Add listeners start
        ethereum.on("accountsChanged", (accounts) => {
          dispatch(updateAccount(accountId));
        });
        ethereum.on("chainChanged", () => {
          window.location.reload();
        });
      } catch (e) {}
    } catch (e) {
      dispatch(connectFailed("Something went wrong." + e));
    }

    try {
    } catch (err) {
      dispatch(connectFailed("Something went wrong." + err));
    }
  };
};

export const reSyncAccount = (blockchain) => {
  return async (dispatch) => {
    let transactions = [];
    await blockchain.smartContract.getPastEvents(
      "Transfer",
      {
        fromBlock: 0,
        toBlock: "latest",
        filter: { to: blockchain.account },
      },
      (error, events) => {
        if (!error) {
          console.log("events", events);
          events.forEach((event) => {
            const t = {
              txnHash: event.transactionHash,
              tokenId: event.returnValues.tokenId,
              to: event.returnValues.to,
              id: event.id,
            };

            transactions.push(t);
          });
          // for (var i = 0; i < events.length; i++) {
          //   transactions.push(events[i]);
          //   console.log(
          //     events[i].returnValues,
          //     events[i].returnValues.tokenId
          //   );
          // }
        }
      }
    );

    dispatch(connectSuccess({ ...blockchain, transactions }));
  };
};

const getTokenData = async (nft, trans) => {
  const tokenURI = await nft.methods.tokenURI(trans.tokenId).call();
  const tokenAPI = await fetch(tokenURI);
  const tokenData = await tokenAPI.json();
  return { ...tokenData, tokenURI };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
export const disconnect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      dispatch(updateAccount(null));
    }
  };
};
