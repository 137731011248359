import { Card, Col, Container, Image, Row } from "react-bootstrap";
import Logo from "../../assets/images/PROJECTJ_BLACK.png";
import Video from '../../assets/videos/jodyvideo.gif';
import { Mint } from "../../Mint/Mint";
import { Social } from "../Social/Social";
import "./dapp1.css";
import Twitter from "./../../assets/images/twitterwhite.svg";
import smlogowall from "./../../assets/images/smlogowall.png";
import {
  getContractNft,
  nftAbi,
  nftAddress,
  requiredChainId,
  requiredChainIdName,
  requiredChainIdNum
} from "./smart-contract";


export const Dapp1 = () => {
  const content = (
    <>
      <Container fluid className="main__container">
        <Container>
          <Row >
            <Col>
              <div className="top-navbar">
                <div className="d-flex align-items-center text-center justify-content-between">
                  <div className="logo-container">
                    <div className="image-responsive" >
                      <Image src={Logo} width='100%' className="website-logo" />
                    </div>
                  </div>

                </div>
              </div>
            </Col>
          </Row>
          <Container className="center__mint__area">
            <Row className="d-flex align-items-center mint-area">
              <Col md={6} style={{ zIndex: 999 }}>
                <div className="side-img text-center">
                
              
                  { <Image
                    src={Video}
                    fluid
                    
                    style={{ zIndex: 999 }}
                  /> }
                </div>
              </Col>
              <Col md={6}>
                <Card className="text-center bg-black text-white" >
                  <Card.Body className="text-center">
                    <Row >
                      <Col >
                        <Mint
                          smartContract={{
                            getContractNft,
                            nftAbi,
                            nftAddress,
                            requiredChainId,
                            requiredChainIdName,
                            requiredChainIdNum,
                          }}
                          totalSupply={888}
                          heading={"MINT PROJECT J"}
                          price={0.00}
                          maxNfts={1}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              
              <div className="social block"> <a
                  href="https://twitter.com/SpectrumPass"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter Icon"    width="24px" />
                </a>
                </div>

                <img src={smlogowall} alt="Twitter Icon"    width="24px" />
            </Row>
          
          </Container>

       

        </Container>
      </Container>
    </>
  );

  return (
    <>
      <div className="section main_slider mt-0" id="home">
        {/* <Container fluid className="videoWrapper1"></Container> */}
        <div className="video-text-container" style={{ minHeight: 520 }}>
          {content}
        </div>


      </div>
    </>
  );
};
