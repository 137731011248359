// log

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (blockchain) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let name = await blockchain.smartContract.methods.name().call();
      let totalSupply = await blockchain.smartContract.methods
        .maxSupply()
        .call();
      //let cost = await blockchain.smartContract.methods.purchaseTokensPresale().call();
      let cost =0;
      dispatch(
        fetchDataSuccess({
          name,
          totalSupply,
          cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
