import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/images/LOGO.png";
import { Social } from "../components/Social/Social";
import { connect } from "./redux/blockchain/blockchainActions";
import { SpectrumMint } from "./SpectrumMint";

const backgroundImage = "";

export const Mint = (props) => {
  const { smartContract } = props;

  const dispatch = useDispatch();

  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  return (
    <>
      <div className="mint-action-box">
        <SpectrumMint
          blockchain={blockchain}
          smartContract={smartContract}
          maxSupply={props.maxSupply}
          heading={props.heading}
          price={props.price}
          maxNfts={props.maxNfts}
        />
        {(blockchain.account === "" || blockchain.smartContract === null) && (
          <Button
            style={{ marginLeft: 3 }}
            className="btn btn-transparent btn-bordered ml-2"
            variant="outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect(smartContract));
            }}
          >
            {/* <Image
                                src={MetaMaskImg}
                                className="connect-metamask"
                              /> */}
            CONNECT WALLET
          </Button>
        )}
      </div>
      {(!blockchain.account || blockchain.smartContract === null) && (
        <div className="error-box" style={{ marginTop: 50 }}>
          {blockchain.errorMsg !== "" && <p>{blockchain.errorMsg}</p>}
        </div>
      )}
    </>
  );

  const content = (
    <>
      <Container fluid className="main__container">
        <Container>
          <Row>
            <Col>
              <div className="top-navbar">
                <div className="d-flex align-items-center text-center justify-content-between">
                  <div className="logo-container">
                    <div className="image-responsive">
                      <Image src={Logo} className="website-logo" />
                    </div>
                  </div>

                  <div className="social">{/*  */}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Container className="center__mint__area">
            <Row className="d-flex align-items-center mint-area">
              <Col md={6} style={{ zIndex: 999 }}>
                <div className="side-img text-center">
                  {/* <Image
                    src={sideImg}
                    fluid
                    roundedCircle
                    style={{ zIndex: 999 }}
                  /> */}
                </div>
              </Col>
              <Col md={6}>
                <Card className="text-center mint-card">
                  <Card.Body className="text-center">
                    <Row>
                      <Col md={12}>
                        {/* {remainingToken >= maxSupply && (
                          <>
                            {remainingToken}/{maxSupply}
                            <h4>The sale has ended.</h4>
                            <Button className="btn-connect" variant="primary">
                              <a
                                target={"_blank"}
                                href={"https://opensea.io"}
                                rel="noreferrer"
                              >
                                Opensea.io
                              </a>
                            </Button>
                          </>
                        )} */}
                        {/* <div className="price-box">
                        <span id="ETH">{nftPrice}</span> ETH + Gas Fee Required
                      </div> */}
                        <div className="mint-action-box">
                          <SpectrumMint
                            blockchain={blockchain}
                            smartContract={smartContract}
                          />
                          {(blockchain.account === "" ||
                            blockchain.smartContract === null) && (
                            <Button
                              style={{ marginLeft: 3 }}
                              className="btn btn-transparent btn-bordered ml-2"
                              variant="outline-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect(smartContract));
                              }}
                            >
                              {/* <Image
                                src={MetaMaskImg}
                                className="connect-metamask"
                              /> */}
                              Connect wallet
                            </Button>
                          )}
                        </div>
                        {(blockchain.account === "" ||
                          blockchain.smartContract === null) && (
                          <div className="error-box" style={{ marginTop: 50 }}>
                            {blockchain.errorMsg !== "" && (
                              <p>{blockchain.errorMsg}</p>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Row>
            <Social />
          </Row>
        </Container>
      </Container>
    </>
  );

  return (
    <>
      <div className="section main_slider mt-0" id="home">
        <Container fluid className="videoWrapper"></Container>
        <div className="video-text-container" style={{ minHeight: 520 }}>
          {content}
        </div>
      </div>
    </>
  );
};
