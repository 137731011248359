import keccak256 from "keccak256";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toChecksumAddress } from "web3-utils";
import { getMerkleTree } from "./libs/api";
import { reSyncAccount } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import React, {Component} from 'react';
import {FormControl, FormGroup, FormLabel,InputGroup } from 'react-bootstrap';
const backgroundImage = "";

export const SpectrumMint = (props) => {
  const { blockchain, smartContract } = props;
  const getContractNft = smartContract.getContractNft;

  const endDate = new Date("2022-07-30T14:00:00.000Z");
  const [nowDate, setNowDate] = useState(new Date());

  const dispatch = useDispatch();
  const preSaleStartTime = new Date("2022-07-26T14:00:00.000Z").getTime();
  const saleStartTime = new Date("2022-07-29T00:00:00.000Z").getTime();
  const todayTime = new Date().getTime();

  const [preSaleActive, setPreSaleActive] = useState(
    todayTime >= preSaleStartTime && todayTime < saleStartTime
  );
  const [saleActive, saleSaleActive] = useState(todayTime >= saleStartTime);
  const [nftPrice, setNftPrice] = useState(props.price || 0);
  const [maxNfts, setMaxNfts] = useState(props.maxNfts || 1);
  const [maxSupply, setmaxSupply] = useState(props.maxSupply || 1000);

  const data = useSelector((state) => state.data);
  const [isCollectionHolder, setIsCollectionHolder] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [isMinted, setIsMinted] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintingNft, setMintingNft] = useState(1);
  const [loading, setLoading] = useState(false);
  const [remainingToken, setRemaining] = useState(0);
  const [isWhiteListed, setIsWhiteListed] = useState(true);

  const web3 = !!blockchain.web3 ? blockchain.web3 : null;
  const [accountId, setAccountId] = useState(null);

  useEffect(() => {
    if (!!blockchain.account) {
      setAccountId(blockchain.account);
    }
  }, [blockchain]);

  const _remainToken = async () => {
    if (accountId && web3) {
      try {
        const token = getContractNft(web3);
  
        const tokenMinted = await token.methods.circulatingSupply().call();
        return setRemaining(tokenMinted);
      } catch (e) {}
    }
    // return _doThis(async (account, web3) => {

    // }, false);
  };

  const mintNfts = async () => {
    const web3 = blockchain.web3;
    const account = blockchain.account;
    if (!mintingNft || isNaN(mintingNft)) {
      alert("Enter some nft quantity to buy");
      return;
    }
console.log("minting how many? " +mintingNft)
    setFeedback("Minting ...");
    setClaimingNft(true);

    const nft = getContractNft(web3);

    try {
      // Mainnet

      let price = web3.utils.fromWei(
        await (saleActive
          ? nft.methods.costPerNft().call()
          : nft.methods.costPerNft().call())
      );


     if (preSaleActive) {
        console.log("is presales")
       
        price = web3.utils.fromWei(
          await (preSaleActive
            ? nft.methods.itemPricePresale().call()
            : nft.methods.itemPricePresale().call())
        );
      }

      const _howMany = Number(mintingNft);
      const maxPrice = web3.utils.toWei(
        (Number(price) * _howMany).toString()
      );

      const leaf = keccak256(toChecksumAddress(account));
      const proof = getMerkleTree().getHexProof(leaf);
      //Mainnet
     let purchase = nft.methods.purchaseTokensPresale(mintingNft, proof);
     
      if (preSaleActive){
        purchase = nft.methods.purchaseTokensPresale(mintingNft, proof);

      } else{

      purchase = saleActive
        ? nft.methods.purchaseTokens(mintingNft)
        : nft.methods.purchaseTokens(mintingNft);
      }
      
       
      //This is for rinkeby
      // const purchase = nft.methods.mint(mintingNft);

      // Gas for rinkeby and mainnet
      let options = {
        from: account,
        gas: "0",
        value: maxPrice,
      };

      const estimateGas = Math.trunc(await purchase.estimateGas(options));

      try {
        options = {
          ...options,
          gas: "" + estimateGas,
        };
      } catch (e) {
        parseErrorMessage(e);
        setClaimingNft(false);
        return;
      }

      try {
        setLoading(true);
        await purchase.send(options).on("confirmation", (i) => {
          setFeedback(
            "Your Mint is successful!"
          );
          setClaimingNft(false);
          dispatch(reSyncAccount(blockchain));
          setIsMinted(true);
          syncCollectionHolder();
          dispatch(fetchData(blockchain));
        });
      } catch (e) {
        setLoading(false);

        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      }
    } catch (e) {
      parseErrorMessage(e);
      setClaimingNft(false);
    }

    //_doThis(async (account, web3) => {}, true);
  };

  const parseErrorMessage = (e) => {
    let msg = null;
    let errorMsg = e.message.toString();

    console.log(errorMsg);
    try {
      let a = e.message;
      msg = JSON.parse(
        a.substring(a.indexOf("{"), a.lastIndexOf("}") + 1)
      ).message;
      msg = msg.replace("err: ", "");
      msg = msg.replace("execution reverted: ", "");
    } catch (eiii) {}

    if (errorMsg.indexOf("Presale is not active") > -1) {
      msg = "Presale is not live.";
    } else if (errorMsg.indexOf("You are not in presale") > -1) {
      msg = "You are not whitelisted.";
    } else if (errorMsg.indexOf("the contract is paused") > -1){
      msg = "Contract is paused.";
    }else if (errorMsg.indexOf("Purchase exceeds max allowed") > -1){
      msg = "You have reached your max allocated mint. Please wait for Public mint.";
    }

    if (!msg || msg === undefined) {
      msg = "Insufficient funds";
    }

    setFeedback("Sorry! " + msg);
  };

  const getData = () => {
    if (!!blockchain.account && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain));
      _remainToken();

      setInterval(() => {
        _remainToken();
      }, 3000);
    }
  };
  useEffect(() => {
    getData();
    getContractCores();
    _remainToken();
  }, [blockchain.account, accountId]);

  const syncCollectionHolder = async () => {
    if (accountId && web3) {
      console.log("accountId", accountId);
      const nft = getContractNft(web3);
      const ownerTokens = await nft.methods
        .balanceOf(blockchain.account)
        .call();

        if (preSaleActive){

      setIsCollectionHolder(ownerTokens > 2);
        }
    }
    // if (!!blockchain.account) {
    //   _doThis(async (account, web3) => {
    //   });
    // }
  };

  useEffect(() => {
    syncCollectionHolder();
  }, [accountId]);

  const getContractCores = async () => {
    if (accountId && web3) {
      const nft = getContractNft(web3);

      let priceMethod = nft.methods.costPerNft();
      let maxMintAmountMethod = nft.methods.presaleMaxMint();
      let maxSupplyMethod = nft.methods.maxSupply();
      const token = getContractNft(web3);
      const tokenMinted = await token.methods.totalSupply().call();

      if (saleActive) {
        priceMethod = nft.methods.costPerNft();
        maxMintAmountMethod = nft.methods.maxMintAmount();
      } else if (preSaleActive) {
        console.log("is presales")
        priceMethod = nft.methods.itemPricePresale();
        maxMintAmountMethod = nft.methods.maxMintAmount();
      }

      const price = web3.utils.fromWei(await priceMethod.call());
      const maxMintAmount = await maxMintAmountMethod.call();
      const maxSupply = await maxSupplyMethod.call();

      console.log("what is the price "+price)
      setNftPrice(price);
      setMaxNfts(maxMintAmount);
      setmaxSupply(parseInt(maxSupply));
      setRemaining(tokenMinted);
    }
    // _doThis(async (account, web3) => {
    //   try {
    //   } catch (e) {}
    // }, false);
  };

  const onChangeNft = useCallback(
    (value) => {
      value = parseInt(value);
      value = isNaN(value) ? 1 : value;
      if (value > maxNfts) {
        setMintingNft(maxNfts);
      } else if (value < 2) {
        setMintingNft(1);
      } else {
        setMintingNft(value);
      }
    },
    [maxNfts]
  );

  return (
    <>
      <div className="mint-box-container">
        <div className="mint-box-header">
          <h1 className="sale-head">
            {props.heading || "Mint SPECTRUM PASS"}
          </h1>
          <h2 className="supply-head">
          {!!accountId && (
            <h2 className="supply-head">
              Minted : {remainingToken}/{maxSupply}
            </h2>
          )}
          </h2>
        </div>

        <div className="mint-box">
         <p>How many would you like to mint?</p>
          <div className="mint-price">
            <p> {(nftPrice * mintingNft).toFixed(3)} ETH</p>
          </div> 
          <div className="mint-btn-container d-flex justify-content-center flex-column ">
            <div className="mint-box d-flex align-items-center flex-column justify-content-center">
              <FormGroup>
                <InputGroup className="mb-3 mint-input-group">
                  <InputGroup.Text
                    className="input-group-prepend"
                    onClick={() =>
                      setMintingNft((mintingNft) =>
                        mintingNft > 2 ? mintingNft - 1 : 1
                      )
                    }
                  >
                    -
                  </InputGroup.Text>
                  <FormControl
                    className="text-center"
                    value={mintingNft}
                    aria-label=""
                    onChange={(e) => onChangeNft(e.target.value)}
                  />
                  <InputGroup.Text
                    className="input-group-append"
                    onClick={() =>
                      setMintingNft((mintingNft) =>
                        mintingNft >= maxNfts ? maxNfts : mintingNft + 1
                      )
                    }
                  >
                    +
                  </InputGroup.Text>
                </InputGroup>
              </FormGroup> 
              {!!blockchain.account && !isCollectionHolder && (
                <>
                  {mintingNft > 0 && (
                    <Button
                      className="btn-mint btn-transparent btn-bordered"
                      variant="outline-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        mintNfts();
                        getData();
                      }}
                    >
                      {claimingNft ? "Minting..." : `Mint`}
                    </Button>
                  )}
                  {mintingNft === 0 && (
                    <Button
                      className="btn-mint btn-transparent btn-bordered disabled"
                      variant="outline-secondary"
                    >
                      Select an amount to mint
                    </Button>
                  )}
                </>
              )}
              {isCollectionHolder && feedback == "" && (
                <>
                  <h4 className="supply-head">
                  You already have minted Project J.
                  </h4>
                </>
              )}
            </div>
            <div className="error-box">
              {blockchain.errorMsg !== "" && <p>{blockchain.errorMsg}</p>}
              <br />
              {feedback !== "" && <p>{feedback}</p>}
              <br />
              {!isWhiteListed && `You are not whitelisted`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
