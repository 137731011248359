import detectEthereumProvider from "@metamask/detect-provider";
import MetaMaskOnboarding from "@metamask/onboarding";
import hash from "keccak256";
import { MerkleTree } from "merkletreejs";
import Web3 from "web3";
import { toChecksumAddress } from "web3-utils";
import { requiredChainId, requiredChainIdName } from "./smart-contract";

const msg_mobile = "Please use MetaMask!";
const msg_desk = "Please intall MetaMask Wallet extension";
const deepLink = "https://metamask.app.link/dapp/spookiesclub.com/";

const msg_chain = `Please switch network to ${requiredChainIdName}!`;
export const _doThis = async (todo: any = null, prompt: any = true) => {
  const isMobile = require("is-mobile")();

  if (prompt && !MetaMaskOnboarding.isMetaMaskInstalled()) {
    if (isMobile) {
      if (window.confirm(msg_mobile)) window.location.href = deepLink;
    } else {
      if (window.confirm(msg_desk)) new MetaMaskOnboarding().startOnboarding();
    }
  }

  const ethereum: any = await detectEthereumProvider();

  if (ethereum && (prompt || (await ethereum.isConnected()))) {
    const [account] = await ethereum.request({
      method: "eth_requestAccounts",
    });
    const chainId = await ethereum.request({
      method: "eth_chainId",
    });
    // console.log({ chainId });

    if (chainId === requiredChainId) todo && todo(account, new Web3(ethereum));
    // else alert(msg_chain);
  }
};

export const getMerkleTree = () => {
  const elements = [
    "0xAfcb0ddA64D15b6E1e37a8730625d72E17e6d274",
    "0x34728A74F02004E06c5d3CB879d379675B729931",
    "0xa23C3f27402C95Da1188400e46cf74b1Cd18caBC",
    "0x04a903B982242143C57Ed39F31eE0C2ed22e1a1a",
    "0xF9bD5a333F8B30F4BEbFbA0faC7afB5BE07996f8",
    "0x8D08C764f9fEF34a212E7C52655458B505EF1Dd6",
    "0xF440d8C2AC1c787721AfE8cBbABc279657706FD0",
    "0x890860eeCFCADD819C0E38be23F26D1779ea5c6F",
"0x62C19577Aa65A3188f477240A74E9CD41ebA5DEa",
"0x3234aA76a215a15e51e92A9053C145Bc0938FC8c",
"0xf2A951F8D55F6DeD59919255DB66ECA31dEfC73C",
"0x765945921bDbBE3aea8B719B5CC3052196ab30B1",
"0x8c97B171738aA8fdbD0bb73a440Be2424F840635",
"0x989d4876DA6F646B605F9d8A659CDf65cB055Fab",
"0x16A39871f67B92Fa271D76D7848236d671F9Fcaa",
"0x9c006dfd0113DC4e7579Ce6fF0202F9a6A5d67c2",
"0x9C571B2A123F1FA19F9C43ff7de23d20fDfA8E75",
"0x87f872c7283952419e4a2eBCDA8e9Cb3749029b7",
"0x432Ee96eB69BC815Ba0f202D3f4CeF3AC90fe29f",
"0x129c6d64a5b7e1d0d547978cd37acc3b9a9e1473",
"0xDCdB9f9dc95ca33270a11533F51365E4d2E6868f",
"0xDBC29240c6434cEeca58f28d5D9e9402D9781E4d",
"0x1eE492E83739EFf40eBcA92fDF09404619E80D81",
"0xf6e16701911C6C2A15307E621C2A77CB82ed02A2",
"0x772fa6f2f52e820ed8C476CB70Ec1fc6514De8ef",
"0x84E8dD15880De1e5eA13288dDFd9C3Fd2E72f21e",
"0x2913c5cCbf6588169772B66d70018122Edb67f24",
"0x4439f1b2000c3baa207432387ba635ef6ca56ae0",
"0x26a72110a8136133d59be263a92b6aeecfd4d81b",
"0x340ad46d2b52bb512088923d211146c12469d46c",
"0x05d987577901933e6e6e11ddc8c3b3592addffc8",
"0x9aceea8ae7c740be255a81d668aa49434d9eb28d",
"0xcF58577Ea5271d2Ce5360e469EEAD0067c356429",
"0x45DD25BD94f1bBb68D40622409fC71C10df5C0Dc",
"0xcc35Ca2A5d989BD0FBf3c6ef17c260672695E0E0",
"0xB617f6348E5DBba03F0F100750C371166fc1356d",
"0xd2dccb45C2ab276488D0D9d38Dd0A684c88bdaCD",
"0x531785d28605b2056Fa0F97462DA5EecfbbE6fF6",
"0x2874A3f29dCe719a5776F861a2ba76faC6d680c2",
"0x96cEc8950196C15dd62AEC2954028d6C542A80F9",
"0x7a4F6D5d2036191472ac7EA170E2a0D9666D44D5",
"0x243f96b3bD910C30bB429f2f4120fd54bdAB1A32",
"0x33CC6854DBf13C5cB2e691a62940D04dfc8B46B9",
"0xDA4cdF2FC730Ab0A02df2273a9D88E799eF428EF",
"0x5713F69Ac8D64049747274eF19F54f8DCce67184",
"0xD3B254A9F2651a518531Ca4F440B73b9bAD50802",
"0x705D2488020B87cAC572C58999832B771c8f6e80",
"0xde34C5f5E2495BE62D10D9FBee95a86835D0d5B6",
"0x47a740639f8Ce3673c05312752C0549D5a909d0a",
"0x101A7b7935268d85420237bcA6b9A08DC0f5d835",
"0x6c5F980134C468F50B45508DDA5A0300F2A56b0e",
"0xD9AF96861dE6992b299e9aC004Aa4c68771d0815",
"0x8BbBACB26cff922A9faCb2b3f3Bfd5a073552153",
"0x854569C81C1c7Fd8D664924AFF357bF5C4368a62",
"0x3baEBBd1Abdf59917E2ff759922A355DB9A8eAD0",
"0xc974dF7cf267Fdd454776CC64725Dd6f77F0212A",
"0x76a3B1104d06ba886Af27B8084fBF69AAEb2dD56",
"0xCbB864241e9d853029D5666a9Ee7c2d9cA966110",
"0x7164bAD5e6aBaB842B04C91B377e8e7bf8504b97",
"0xD1aaE2d4E0703e2373b508D4Ac3b81A48B42DF1a",
"0xF4AaDdB86cA7B8115342e7bCA3d100b3e8D3c263",
"0xEA3559f74eB12e5e954F35a9A337D1426373CcF3",
"0x9E85f79b05D4EF8BEfa3aa70125495C8b1eC8715",
"0xF5300f2F8182799187932557f452C90Ae4289D29",
"0x31de40097AC392A50F6C2f9c7945211170B57290",
"0x6048Ac11cc1098d3e42E7b8BBE7E84463476dfa8",
"0x87AD1687e91e1fBaA9b1Fb30155f43Ee535f35f7",
"0xb4ba88887EAe71433a5e265aA5d5Db04D90D7b2f",
"0x8eb4BDCBB61D48052cdc429C53988Ff719C89205",
"0xDd2F694ff2F0e365EAEF68860F4ad78b645DCcf5",
"0x3e843a474609CFd493D3337C996Ca1ECE432A707",
"0xf51Ec2b80FF3805196143f8998A45a5786782E21",
"0xd01236cD714ab5b1e7ac0c7502DC431Ba46364Bc",
"0x1Cd5c81f65c568a8E73473784c34964E2412b421",
"0x25eb41cA2549b06822763a4ABAdF123029a75495",
"0xe4aF69ee15dE386cf692FFb34307E34762b54fF4",
"0xAf11DF798Ec726433AbaA5486Cf1555335DD30e2",
"0xCf7f51347E9420268375dF12C6BaD832df233146",
"0xE5cEa7D7346BefBe0e34142FE98c5C957091b429",
"0x06859Cf8D2feCdC74D386f4c2B83a5d5EEbFC41c",
"0xaf9df8B48d9Cd22ab491a025Ba1a82B489b20B4d",
"0x16b41506e63a167cC1dD6FB80e1d520160C76D07",
"0xaBA58B2D6e9045257C28bF31fF22D43B21a7902a",
"0x1e868E0F5948Fc94ed99DDe0d0AbA939E7677b47",
"0x18a54340324f442d7523D2d0803D1911Ebc20294",
"0x4060d22091a82c7b8Ff13C7BAAd7873CB2127807",
"0x61e5395E289d4507D456532276187318bD5af3a7",
"0xf783369B88D2d653277A34dC65CdCcf21240dA84",
"0x69F34C600f58e833688c595929ddA89A859e9863",
"0x827f300aBFb8A979C875e869075B3De691FC8E71",
"0xA67B621Ea4d0F4Fb817eAc56013cC4B49f818e71",
"0x9973ADF0AC4353984b85E57f4156cF57F6E69e0B",
"0x91B7C14E8CdBdD6ad82723bd8Fed47F2d79c9861",
"0xB969FEc6544ef1b4067DD9218aA966Ee8D2f0426",
"0x4f01B03722383b2C28e0afA5981157b9FE00D625",
"0xDEd23ebc9dd911E1C694b8fFB6F78F286664BE42",
"0x6E8C0DD97Ab7070cb8fD661122D3d0532CC79D33",
"0x5a9435c0EaAa532bD9C4Dc831DF9537d8f6E6A4d",
"0x8e62D169fFb75C05eD883332DcbBc3031ba6789d",
"0x02dEF537a831827ceD760a4486D7Fff8C62C8462",
"0xecb42e924262a976Bc7bed2a6c22C9A16219a09d",
"0xbf4A1BC3B6925AcE9531726932e1Eb95636d86c4",
"0x3Dc72E7bCE0cBd60C189DAf16f671EC404E6f8D5",
"0x6185b474E0b955814A2EaeC8b5051B3E3447a0e6",
"0x1AD989CA0511caa74F2Eb26f8654D7e00356a286",
"0xF7Fa2b9ccEE29d6d760377d040e6Ef92bf9b09Ff",
"0xcbEDC0271c8034a500A8D12F60762F424eE55Bf8",
"0xD7f5caFF25b559a5Fc6CCB5f343cbD01e83DaD34",
"0xC1396080617fb1559b97EF3147dBFc76E84a9265",
"0x843f59C87a9D94505Ca65E9CDD1D01B6a7Ce192f",
"0x96232D041648046c17f428B3D7b5B8363944188b",
"0x00BBc7aAa3E598dAC0ef90dCEFfb6A70737a5D9A",
"0xF3889295D4bBa85Ed79764323C46E38A26bE5316",
"0x0f58903887a0b6Dc016750d165A0968aEcc8d0B5",
"0x4CDe10196C770AC25c1c6A50F523A52EA6807c27",
"0x6ed655ED54910C1f85391f8e755F92927A381439",
"0x453f2a8e2ee8107E056BC71CDBF29322a1B73a53",
"0x0907Bb13fefC50e25B0bFBB7C1Af9C2e02dbDCE7",
"0x9f83bd3F27663447FC368A50Af8a7fa6789dAbD2",
"0xb5619Ba9D7f67254e4C53c8bE903d951B551C9a5",
"0xCb7676c27288C342CD9EEa5D6C78CaDE95d67572",
"0x20e5A57F1f92506dD76eb109083dE2E2d3e2Df64",
"0x6D307B11482c71Ac933b2E8a91f3Aa0a28de12Eb",
"0x0f863ba3aacEdfDCe335DDbCf7a77f8aee9dF069",
"0x54335d6bfA803081E76CBb38ac0473937972C168",
"0xa4B293237D9F11443D7aEdF022aDe940d58589B1",
"0x5572602e836d2BA256705B7FAc2100Ac49aE60A5",
"0xEA3701a5AACAbc3D5195c4d144ED7d6c88d1Ae87",
"0xdA58F8d6AF92C270bF38cbabAa0D8f65045C30cd",
"0x7afadef79beda1b40de7c8e25b9228a7ecad11e4",
"0x39dbF3787aA808f17FfF2f66Be8937Ab7FbF8021",
"0xB4f37aF517B7B51F62dED79FA4347F94D76e1e2A",
"0x6C25d0B9753B76022470F8712a592cc543554379",
"0x8c2A36F85d92a3ece722fd14529E11390e2994D7",
"0x7E1a26DC7dd79638F5C21A7E6a0c520C540f1749",
"0xA2a2D083333FB2FBBe597dfF0d91F0eEC436A288",
"0xd770b3Dbb3A29008eEaC64AE0deBE7BDe41bCd02",
"0x88B55C9B0f03f40e3a455B8836019740f037E4D7",
"0xA1caAEB1d84aC460434aa3DF10E082f789EC0D7A",
"0xd08A0E5c4faf114880A732C1E951D8fE56839118",
"0x0fEbB3fb06D174167A0296c8Fb8709Ff468A182c",
"0xE0c539Da4bD3B2fFeFA32B785EDb8840915e3B5E",
"0xB81492d52C86D095fCb0E0cc3dd8dd74EBEE21B9",
"0xf548687E09127a2e5b195D4Eee24cef6a60c6CbB",
"0x1692608497aEB72D3AC6f088E5C6135a809Fa1aA",
"0xb7b2297ccb4b921deb22f4e43d7ebdde7a0a4d45",
"0x2CF82488B1d7DBdA0fb3D5b96F6881701e62a7B8",
"0xC03399e8f12a1e9b5Ea435112E494543516A6d7B",
"0x6970aD16063C488e1293Eb3896FeBD592ee6315B",
"0xf8298234C70d64457D56139FEAbbE3BE7122a65D",
"0x51015f7bfE495Eb5C1daeddaff63d0bA39eDc285",
"0x91431023bB0E1C66710283fe54d397E7126C225e",
"0x010C7034DB4E012c52fb3C38bf477b0f70c3d271",
"0xAA69F0D6a7953cc773cb7b534E7E889da2268Fff",
"0x1D3adB9ab924451C25495d2b55c13059EA4D7dc6",
"0x023f5B749860964393ae1217BB5d9bB56fe5dF23",
"0xbc811A7d71018F6056642C2801207b699B1A5469",
"0xc6A05abC90EF30635398E7Fd183cd3c524cd3807",
"0xcB516681414b65CBF0AFd943acE928628305f2AF",
"0xC3E48D6353c69F25780A04D63E7CfB2F696F0191",
"0xea31107781fAd279457Af9B3B60020045DfCD39b",
"0x3DF5bE5D7Efa7Fbe698e74B95d683FB7f9B3548a",
"0xEE667a3c89C7EE6E5a9595F998D32642DFd0931f",
"0x7381Defba46c140F81aA6084Cc3F8E585272865e",
"0xcCB285463a5FaB286Da743F5eEa1E9D7cC6D3637",
"0x740E7E7F89305D947DAbD320816bc46E6ecDb808",
"0x5986554fd85E8c4A40B03C95c44FF2b3f408967C",
"0x7863751226dc8978dD24e7724D13E1173027559b",
"0xa480da36187d1438c708b425cf16babde6845675",
"0x35B02C0a9943c6ee2F1Dd911a79E579bBc36482b",
"0x0E21231B24af7F59Ff9252EE3C7B715Bd7F8d5f8",
"0xfc02F69F7A5E47065EBe59C8F697bba2E86CE103",
"0xc775b26978F5261Af0411247C17345598e386928",
"0x315C591030e2Fc13f492FafC3a110bd0914e67BA",
"0xE96995006efC68f5eB9F9E46ABA26e9F1e3e967e",
"0xf53EAf96ad7c57220735e577977443A27e6Bd594",
"0xc0aFEADc6F1562E90d1693efdCDa97f8bBc8fc36",
"0xc8751a1B9ED6a5562d009E5aD5067B6131fDB346",
"0xE802Dc9E3C3a13505565cF7137b510A9da21D4d0",
"0xdc5742c5A1DA0f9356a442eD1e4cA5662235DFe8",
"0x857e72Fbf579Aa99e981e4CEC3C7F291298Fe875",
"0x66e8BEEf553ffe55209720C66F502815643e4D12",
"0x000736D66fb4E46163a39C3476793662444f2912",
"0x32289cdA97cD3708e178EB597Cb3372D7f5D46e3",
"0xCACa8477e84fe1509420B2E24790c5e4047Bc605",
"0xcC2b2724c4bdf6c40dF06E0644E16379A7879aB5",
"0x6aA71F6434b42a9769f29159bA90a7840c1aeD9F",
"0x41e6732F0bbe183EaA772F75BCAC2398D817B95F",
"0xcEafa40265dffEfa1d2a10fB69a58F2d74973428",
"0x0dcD05914C75A62471F35c6f3F361F84c39DfaB5",
"0x978DC1A5978BBeD895B5d01BF82FB5229d31185b",
"0x6F54040bdc2550F87B3DE9D744636a23D65a14C1",
"0x2285a59Fb49f6beb56cEFaF0163646a1E33235fa",
"0xe067214Eb3071A137489cB1E292a3C53A80967dF",
"0xE14207129A7Fc54c60399d8a3CFfEB640f6A34b8",
"0x0B5C326d1a1846256613F57c9742f8b8d37578D5",
"0x6e4ec434353FE3A7d9D0B5e0Ba4500d9945289Df",
"0x9c9217783E69C7Da753aFa988198308412241C8E",
"0xe04a058C8Bdd33FbaA973eF129DeffD63a606348",
"0xC509F1Ce53AFb9BEC45cb258506aA5862568C44f",
"0x38ec19C17f36B02615d3361552A15C91149e3788",
"0xe61252912c463f9A6e083751948C918d37243b30",
"0xE51F3125De131C3d98d1A47491722734A6e2cf7f",
"0xDD0DCE85F151AB6A4728e2E56748Ac40654038FD",
"0xC40195dEbbFB02755C8FaB0B727Ed1d0421C068A",
"0x7E5f079d65F257cCb204851594d821Ef5007FD33",
"0x4D9a66Cc8B2DA9774Bd5CAdF77355A0f66c2146C",
"0xeB8B1154dfE647391fFb709735A91Bf85816D730",
"0x74a92B04dbB385E789cC9d50670B61C4732E5996",
"0x1E93e03cb1798B853262A2b7cA19D7ae642bC8B7",
"0xec42c555c8757000036Bf2Be65AD2dCcC9030593",
"0xC200023258a45435C413F0660Ae749f1f6762a39",
"0x0824dD5Cc7E27A1FB400e9E54BAAf3bF8dA793D0",
"0x7144C19B3038437A64D71101828990BD95Ed1B74",
"0x2D643Deb9adf607422E47369DFD53C0636C0B6e2",
"0xB5Dd16F777d836089De26E03C0F0E03DA7B9698A",
"0x8a0a677d64E5A428BDD04C2A96610010835396F6",
"0xc7F60C9100A43ff4b6676FcFd64a818318581A02",
"0xDe9023464619639AC0E96961F5820Baa83FBea8e",
"0xC4AC6dff8EF4296CE36B428b9045D8b85E8359E1",
"0x3E158A6c7B7884a0f0D6e4ceEdE4014B173F6A88",
"0x9FF49c0c85E35f23254Cb38c3513ba4241525E12",
"0xF962Ca1d2a8a8381620a705aB86D10E9549E62D3",
"0x778764Bd614D7EdBb0fF2565c3009c721552eDdb",
"0x4d0F7f2D039c9D9D48C058F63015B8bd0577FA74",
"0x4bFde9c1Ab8887452A2a9fB80b6F60e013108eA2",
"0x27d57Cd15feb468471dbE57Ab6288d73980f2D29",
"0x32FBb2f62aD573B9FD6f9735B9b3B646F6B9612B",
"0x2eAE5AF37267007CF956803ce0DFb66468E2df77",
"0x17028962F28d3FD785965434f143CC023F5dD9E1",
"0x43aBb52A608480dAb78199a3C17dF815eff8e182",
"0x48C5631A2172B8AC58Bd5fc98fba3d321d1006Ef",
"0x4f3e48DA06bcE4D17ebE7A07118A04b6d1e555c3",
"0xFa4D2f20C81DA3DFEe57395d031E9848B93B2f85",
"0xb3f2E8A9753da3C3E1fb9a89634C3Aa6671E3c2E",
"0x777bef8D44c5EfE02F3C0a705EC3bff613C82a9C",
"0xEd704B6ED08165e709e8B1F8Dd02B24BedcB8f12",
"0x23f41C82bEccC9EFeE0a11FbdA273dbdB5E3e14A",
"0x6F1A18E399F8Da8B4019c24fbE755f0C96af61fB",
"0x7dA8de0fCeF881344e88756bd0E8E182515cCEF2",
"0x6d31728aF03c007E490cC07e10bC4F42acE85845",
"0x2954817704811D4B46762Aa3bF15d7eEfDcAF854",
"0xc365A21e911CC4ed8d5DBA38a7244722e4aD1B6D",
"0x0F11C916516895F56F9937df3f6d16cD3e87927b",
"0x3FF25B144950F9F0E2628F28d6e99859e67Cd9b9",
"0x5454ec29f90621f8A4BF1d2A7C73aF8B350E5147",
"0x64F8aaeE89eCbbbBF6E563c2ac8472F48Ac7b971",
"0x78E82147b7cB0a10D89BFC895259Dc8E4A1BFe30",
"0xfA949Ce340ef6Ea03c94002543E01f33E0920f62",
"0x1327F35216e3a6785a2943a70de6B159F28809D3",
"0xa44485865165D13D7f1db22a9Ca9440cfAc48f75",
"0x294171C56B60aE7FaFD0A8E948bE6B48BEf01824",
"0xe881439dC9260A539812AeF2E1aeD2767900B7Ac",
"0x8E5A116e6B18b2f9637C74d2C2Ea8C7F3328881b",
"0x1678d5103308bbb5b6e3bbcbdbda0852e1eafb42",
"0x544cBD3CFcfef555bD735538c482Eb29Dab7058c",
"0x51eDb088b39Fd1cE613Eb7fFb06503B83Ef35198",
"0x7E3509E75ba15C240E9E60E02C180a93Aa5e0c6f",
"0x01180F770161351e946f6665befA13beb56898fA",
"0x8520201Ec6Ab08AA35270efDCF28b51a826bcd97",
"0x2D97a5A8Da17227b9b393ca9Be92bfa1a46dF179",
"0x84DC4343F50Ac947954bb1C4aCa7145d92B9C642",
"0xFceb9c7fa8D394247c1dbA1F675Ce7E19e68C310",
"0xb8EF5c01B9eEf0a166a6eb1708D9b17351d7cE0B",
"0x0D9464DcC842c0b9e639Bfd3844aFD1031263980",
"0xb29479ef88dfff8dac466cf6c716e7985c4241c5",
"0x7f873ec58d567e78a2c1dca322cbdb6d490ccb7d",
"0x5c6cE6ccF6D9FC425FC0D5de49c36e270cBdd667",
"0x8b2e6e76a4b85eeff8b80b11fdb07c2ae6b246ae",
"0x8f2df56bCeCB03e9118a8c78b454e7eCf1592872",
"0xf9091Ba435A41F0D461d896cfea6F5E78fFB475e",
"0x7577D3a18177530378d31120f181650e9B353447",
"0xabc43b83Bf88e77D61A5f64b4E50063e8Bb64DaF",
"0xa9d0156094f2acca93a8a066b3fdc1cbe66aa534",
"0xE395257d64BCE9e1795cC59Dd2A625930F15D28B",
"0x56e1b883a7ee8c948d014aF899E2710B8B89dE6d",
"0xAe56383e4463008Ad6965972e4Df5f7185E59b67",
"0xeb5822c32ef52bc46327633e78dd723d6fe08566",
"0xE032BB48a496f87DeAb7E96Ca21360067e56A768",
"0x96b9042D68Be24547E334c816690c8D0065D70b0",
"0x7E58ba8477F7dC9e47Bf65119DcCd8dFCa43B949",
"0x71362355D840C5BB69f21cf7402b9cCD6b8dBA99",
"0x8081A75141dBC89f70d31eece08FF12cfe105e43",
"0x88E0B2A9e2b877C32fa279681a8c833Cc7E6Ff6e",
"0xDe9f863f579FbFAddb270b8995cb24849c47c02e",
"0x11ac46410D574e9a94122A84e91e285b151c9838",
"0x7ac4fbcf235d8e032c244b8da40619272c3b8152",
"0x1c457a373281Fe5fBFE5ec3BefDBa0d09f1857e6",
"0x4982F0fb4c7A6CEA1514Dd4A649231E1CCEcce85",
"0x438235b77E7E1472BABEe72b0520ecac89735dbe",
"0x1a1Ce8054976a38dB8027f1e857478eA423a72E7",
"0xB3557ba0d49bb21b43b2a5AC1dB4b5258b8E6640",
"0x3478B07E6e6a39Fd668B036136C5ae5f62135Be1",
"0x46A20E03f47ec67D2c8E73819C12a6b0c4BbD081",
"0x9560923CfA21904e75eB0427807ca6a7591567a4",
"0x322b4D1Dea0213047Ff23Dd7687b6E0FCC78e546",
"0x70070d4Ff9487755709e8ddC895820B456AF9d9A",
"0xc758921302a979fd28f6DBA6b95276c93E6aDe07",
"0xB361b55b2bC39099853595bfaB7a87c5c3e350Be",
"0x56c03EAc4bE13B3Db5917f46A3126802d62D36A5",
"0x4f2BA675661DFb8A47b194062c8f4A9c5563eF16",
"0x86A050eB5eBE79a9d53da8ED4351bA59A3f4D81D",
"0xE281D3626Dc8677Ca17431dD2290f7c9acd7F75E",
"0xD827745B4Cc5D206A3116049AC4FbC3616939d79",
"0xB75056905Ce1f2cF60c15fF45831c7DeE71EF82b",
"0x0a4e463d635C497c4ee4E4399801fD5036C810aC",
"0xFbaE1BBeF21de7c49bA0FE6c4e981cA81ad7Fa0F",
"0xf4a08D9c5CfA5281A242eEA7c777A4014225B14a",
"0xa5be3cb511c57Dcb0Ff23935101BE1619480015B",
"0xC21633DFe36Ffa788f8ac4eC11B5AaA1987884CF",
"0x1A975092bF71e36F5fd9aF0fe5e30771bFeC702b",
"0x155a3b74c26955Ca5174500A8f83947d7793bDd2",
"0x47A51e662E5A777cC969a0895A41f323D9b7749B",
"0x4181b60C8a76BbB7560b797038B0e6C37184aC7A",
"0xff6202E15773b4283dfa83d3559E70f293DD20Ec",
"0xa9382E09eEbbe26a9be9E96b30C827F593104b13",
"0x2dBC97FAFd68396bf4beC82b89Aa8E78Af2e3077",
"0x5d306c15447fC924b3d0834bEb7363180780A7d8",
"0x2b9C4C307280B906b1a28D6204f50C3997Df2edf",
"0x23Ea24FD1545d7cAb64ad7284471F69B9cC1Fda3",
"0x8e53ad07E9c8b501e99c2f0B0C290dc79e5c23AA",
"0xeBEc6485Fc3E889AEc6E6E92d080620927B4baE2",
"0x75864932e4061561b0F37E1082c04E7Fbd7f1adC",
"0x14a25cF51BCe573026D14a5d01336E579337E398",
"0xf1e55328237900d69d54f0088f35B0aF2Fc62812",
"0xF87AbBA094Bb8051D1A6499C7186Da1d83d4e754",
"0x18E2a3daDF33Ad7C9F0748C486abf576841f30D0",
"0x8950bC0b123488bdd1059ebf17c808b971aD0ab3",
"0xD492b3817AEe97f6aaA900bA2D937335ac415D40",
"0xb0129c941eE1c9c8417A7c2bEf19B64571ad002E",
"0x917826EdB52A7932c534d4B26675B59980626447",
"0xc0c8cbbb5af9e0ee88d50e32aa5286a11a047a36",
"0x890046c32e288882e2e4560afb51cca661a909cd",
"0xa07338c6e972553c3d0743ced2a01add7098d7dd",
"0x425be23d00e196a9f4fe5ae7ee2175a423113401",
"0x59ab04339feacd545b7cfb6cc75214dd5e4ab609",
"0x843d589ec8da308b675cc90577579c45c02e820b",
"0xbf61b8555ad8232a4b71c248098831cf6acb11de",
"0x334980a3255003c5256a75d329f53b64ab69481f",
"0xe4f46496e7aed9c01c98aa6b13a30d131adb7a5c",
"0x6705bb3eb41bb83518b43f4894ba5bf7504d4982",
"0x08a29363da3ad3f942ea39676f70b40b67b33997",
"0xe81f0fe0e49193d0b59d3ea311df61183d886c32",
"0xFFcB4798D953BA01D972932C02908098181aEB20",
"0xEEAe6c14bD4120e6cAA179B3E0C56b6baf3d1bb3",
"0x367A53b5332727b874176ae8D0632a2f80590b1b",
"0x339DB08CFF32FA039295cf4e8D95513e0eACaddA",
"0x2D2282416703192502A548E8Ed15F9862B80EB8C",
"0x177412fc1a03551E92E5CB54E9593fFce322D088",
"0xb61ea4051D15a465aB096b05E433f7F6c40003cc",
"0xae177ccc085F1e68F644E2C34Ea629dF8B58428b",
"0xe4b07CF698321034c069f662AB4Ec772183b84b1",
"0x94A6335DafD6c829d2B0FFD64Ce4B68d32a2dB4a",
"0xd9cf68615ca5c498bF78952926F8ecf3a9199C2b",
"0x895Bae6Cd01a388F4b1AC0091bbA68477f4981A7",
"0x762E0ddbB26aa5b6181E9ebfbF132AEe94F06FfC",
"0x2C702fd5626F39bF945B6C47eec5dCb4DD8F77CC",
"0x5CBFE4302cF8b45bc960d400ffc496Ef21dCb45B",
"0x6f76C2F5A2CD9D9c882410bFA2Fd24864b66530b",
"0xc075BDD8Ff9396273A79274020aec5B72aEEaB5C",
"0x419A9Ae6597BB33b4F0a71862c9873E599484DF5",
"0x7ECF5D15862074d311c282E2b47aEeEdcFd20376",
"0x6E7C8bcAfC32F720Cc4eD883eD5a9E0bE7C2010C",
"0xc3b029ebc26ec6f08b560c4dc033df261fd0f715",
"0xbac44851587e68495f6bf2f91bd8260234d6bcbb",
"0xa6390d340117b04ede43ffdd94d5c390e3c23210",
"0x3df9b38e1d7983cee6153d5711f132f0dc159562",
"0x0667640ab57cb909b343157d718651ea49141a75",
"0xcbf5895af0c15d5d7140fdc43612637cbbc94705",
"0x021e9c24958236a905f2ce4ed67ebda7158bddb2",
"0x46d410b7fbaf1a2d43b48a07c15856ad258120fa",
"0xb5accf73f78a4b903b77cc1d137cc5f273c40266",
"0xb84ff28b09cb747f52e163d785ec4da61b21cc5f",
"0x2e65a8bb0d31a27d17c487e095f53f23a8f981fa",
"0xad972ccea8da18016bc583fd8d1c3fe3f5cace88",
"0x2a881f5c9bf385621f1ba9e5a26eb767886e1705",
"0x10c45c8b122916cb0c4b01f3df54e34b46b0bc9b",
"0x048b8006c7664a5f865698b0c664a71e6127890a",
"0x2575e2445b87d8ad71dfdb5feb7169452acf9f26",
"0x9763771312dfed5bd8f14c224626be2af6c4102a",
"0xcd71315c21bab6ece689c387ffb074696f2ec5a7",
"0x2199632c44900e20d1c4bddfd0f36cb17eba6f8e",
"0x291c9894f99aa53fc3893fdd8549fbaa300734cf",
"0xf6dd849a1c72e4fbd9c8c1d06de5660c0af3e67e",
"0x3245938d5c684301d60275426447a860392072ec",
"0x77863da34ca9d4872b86fb9a4973792ca6a11e16",
"0x783578b0c86fc28b63e455b06153bbbf70e6075d",
"0x151022c8874eb6482d5f0df890fdd8ef415ef89a",
"0x41d534f8c35951309f83bfa46374626e2cae9d59",
"0x564341719f3331250721a0a3227f2265b621b2f2",
"0x605c1445068cbcd706f329309f3831b1681e1052",
"0x5e679d5903809a62d6dabe2ecfd58a9722d34c79",
"0xd9FCBf56aD6793E10181c28B6E418208656f21C2",
"0xdB1a06132dBBC4857b24E628FD630fbf6Dbb6eC7",
"0x5B17F1d35330D0daE58765e7fd9454818E3f5858",
"0x9B082a4Ca71E4f28C1789112F2B6F8c7c20099cf",
"0xD67a977D021B9Ac6D588B10dA76bBA365D58af18",
"0x974794BA38c0170870C3C64583704Dac48c1595a",
"0x3921994fb56AaB7B3002fB974Dd513aCd0cCC507",
"0xeCcee47Df752DD6E9754fa3A745FECb84700BC35",
"0xb3E86A37cc734B1cd463568D1F9E3219D52D8d18",
"0xDddBaB9d3CA8Aefd12D3D64FB00466528c9Af2a4",
"0xD5B46091a8Bee2980e069647f1c5DE781b573986",
"0xfA7B4C0C0740Cb2BEB744Eec9c9280211E0c1553",
"0x2a21B1D174857638C4E14FB3e3124C6FDA31A2bE",
"0x46c57A648C56160f6109072dBC1cCFD6B1eDc8Af",
"0x08AcE91217285e4EEEc5322974861A5420126989",
"0x9a6dFfB91d69bB23CF3B1862298540ACe0eB3DC1",
"0xDaC7d1f8268eadF3C06182297482E1638596E429",
"0xFCf9fFff04fFC641bDFbf3ceBf458918b79C4f11",
"0x4F3019193157680d97a42a47b1313f0b5807E702",
"0xd72A0465563d0B0881489AEe190978e43239c7a3",
"0x43fC9fc353316990318Ad324d361CD75d5218e3D",
"0x70aFb8F0fDf85576cA722c6d564AA276D2c46C8D",
"0x53bE69309193fF9d4df0Fdf57Fa4E396275C4F8A",
"0x0014639B4E1afFF563eaa31F598C978efaC9c900",
"0xE780A34002214698722c7eF0d60da10875913887",
"0xBC8bE2aa3Db139634de4638fCbaA31270242FcDF",
"0x3C9Af509bB5270bE0F9ED8F43F480C8837F9f082",
"0xefbB33D7D2363c9382C0cb08fE6a57f0d155C19f",
"0xe608B51700727049Caf64CD16896AAdEa4a403A3",
"0x7E58d735b4E7faC5b6105dDd998F51208157d2AD",
"0xec49792BdFB0f4ae4014Ac8c1C0a83Ca86222B63",
"0xe55C531E0B9Bb373719b2E81B820A83d90b2492c",
"0x5736aaBaa46D5bAD4A9cD8FB399b429b3f35f95a",
"0x271824a5D9EDd3a1D2a039f2C3eEC50F8d213815",
"0x53FF05FB1a554759c99A6631ED5801948395D564",
"0x17917425921a3AAF507348A5a244227f12e4e658",
"0x03C6286D3A316Ca5eF2fc8aFacf9BADa3Bbd63D2",
"0x3E25153EBC360B9A49a30180313c8041AF72f5E7",
"0xE54A4330F62ea371EA054c9ebfEcEa67c829186b",
"0x2DAa3Ed7F7AdF96dCea723Aa0b3CAd6B5455881B",
"0x06598f9f281Acc62Ff170730A4be2F9fec402C53",
"0x1CF1975453b4f5258850e3865cE903b141AcD0c5",
"0x99Ffa1763dBF1810aDe089AFc172524FF9DE232F",
"0xb056b47D7c030dDD649F4d4642A36CD942B076De",
"0xEF7F5b78eA1a28AE2158fe9b99b4094F007A8E45",
"0xc6533455490861E826c685d71a8b9C0e5b2b411B",
"0xA537c888DA24963D99cb214EE3deEAAa58848B7a",
"0xADB040F4F27eA941BcCAC56B763f577da2eD0311",
"0xfAe3FfA76f1a06324820A276c18F33A83CcfAaE4",
"0x69C83D016Bf7f3b03aCCeCac98AD758062bC2b96",
"0x1F0512de1856662b3B621E910d6B102b117A53E5",
"0x38e9C26c3680d1933564ef5D2696343061C21958",
"0x34b053eF850f952c08bB2b35aD2efe6aF65905c4",
"0xa1c6b7d556bb56408bb3991d29a5e574e10dfe48",
"0x74C002c4Edee654f81F0F6B18E3425ee56eCfd12",
"0x07d86ECC077579796a5F159AbBCA9FA5B4F49EA5",
"0x49BEFC08ce2d290165B1ab1c0aA6eCA33091F217",
"0xA5158Dd50Dd0DfB694d6a38A800081F6e6515f35",
"0x37BaB48c1469c65Fd7126bAc432C4f98B340980B",
"0x87D83ea617bC2f12EA0EA7760f8A82f2C5F19d2f",
"0xaaFb164259D27122530Aef535DCD059F5B3Dc844",
"0x0Be72C280d33d8cCC46AD789bdF0a4b92ac750ce",
"0x84ae79259253231fF6f56e0Ccb65e985A1332BBE",
"0x1Cc4011fBc4ff67Eb08611c0c5735c728b59e646",
"0x3450265c76c0FB04b4a6b5195aebe00314040C6F",
"0xc62cDAc5Ea2ed377cce2f4Ea16Ecb2394f5eE469",
"0x58b0741e13AbCe29474B523AeB38Ab0111aa8f69",
"0x3D8db7079a18Eeb20B77E22a1ED88EdA5F14382f",
"0xd836da33a6629548271eb9ef4f62D083376eB4A6",
"0x3998e8De5fC37131b5D27eB887DdEb532945EC73",
"0x5Bf316ABa4e651DfaE033B92Fc2E22198A8c70b8",
"0xd909AaDc2b47eb58c2Fd42C088Fe17Bf0b9a7a15",
"0xd674533663b9f6196fd5624000070ddb8977b3d8",
"0x81fE7AC2bE6b608AEA26Fe52Ecb9857B437A1080",
"0x17ceDdA24ce320f5A9Eb1d1223F92EDad5294eFe",
"0xC68304E439E04b0eA0C0C07A021a26bF708F7669",
"0x2F1390eC03A03e57a1406BDef57c9cF487f62B78",
"0x23046D9aa616A390Aab7fAbAFC944A593141a66a",
"0x6299569aDEeaBaCF5556310Be3BbD7367DbD25d4",
"0xc7502c148b7cf1f9fE9e7a255a77F9C1fd7DCCaF",
"0x4dA1DE0ECb3215a6714e697682847DECaEc44B14",
"0x4c54d8EEc737Ca6DB9474E2958c17D065f19B196",
"0x7Eca7b2A0b7170DE1Fe3DC8ABb3007d60BE382Fc",
"0x4423225b1EC18F4156355425FCf29B1f59cAFfEB",
"0xE136C0D0039aFAe187F022a5e2F37B8Bf5d176a9",
"0x5D5Cc8fab6003CC912Db719B628293860aD06cD3",
"0xd15961C1c2Acd134236b90D6700589c977bCFbe7",
"0xab873Db0fB910AeeeaE62085ff172ca385c0eBeE",
"0x4eB166aA490547E12dD3a9EDed8D2b5E8E5De0B5",
"0x8008A26d56cc221199A4E708cFc33e2a700d4fD7",
"0x4a347d1D36c7A5BE7D2e59e1E34aF373BE96e6Bd",
"0x12eeA318976af59F266B99B8f2245c3cdB8c5CC2",
"0x8Bee657Df79ab411a93bcAEcae6019d0Ff8a0Dd5",
"0x7c5b40058473Fc813Ce0e024dcb0c9Dce3B84BaE",
"0xD4713F98CC72AA1683acd987Ce9f4967a91C5059",
"0x8cce47C063faa68bDee2C5abed240F558Eb749Ef",
"0xB32F88B89365D428751AA060Ab1F983d012AC423",
"0x1868C02b129dAB214D2E55eE5c9531D4De8aCc40",
"0x88B05C27E66E8DF10AC259008e820f705Ec0Ae34",
"0x9c7CBC31b3cF99347818425BaBc843146FB02e69",
"0x02D4BEf35A186396E7238d5Baa30518E0623B120",
"0x1d13949903346D96014eD0264f0109b4aE5d4185",
"0x6eA4dD984c3fF2BA20edefD0381d32d832B13152",
"0x3A78a990DcfE1fa140701CB4A02c7B9D8c3f3E9e",
"0xFc84F9cE5A0BA010344D690f849988FA033031a5",
"0xBf692a5C58F40789bE47B532D6277143CfD4d0eF",
"0xc88370e40d7D958F5D8B2267DF46991200019081",
"0x5e835798876124f5bdea5682a37f15100ee58903",
"0xBe8EDBa2Bf6a443c36342980681391E640639B9B",
"0xcc47F370D7b0892E5be50e3642d7815aBb13c449",
"0xaEFC57fD6956ed1Ea902d0DA3C351DA2F036A8Dd",
"0x8219277A3EA5c1C2Af71377B1c91Aa7E4258910B",
"0xAe150Fe9af090eCEdEd52dbADeDA6236f3acB865",
"0xb6d19afe6de6c1ab49b964e202ebbf6b8e590a33",
"0x2CF1E7137EFaE14caA26b9bD60cf16fd52D5157E",
"0x489d20b135c7e7325f22dE88FEaB1b4B0BD61A8F",
"0xE120edDd8A1FEA7D73AaD75d8eD8406988B2C98D",
"0x5489a663225FeE3aA5BB8426F388bafA3ff3482C",
"0x456f6e34aC23EbF3478F3AdE23c7F633C81bD461",
"0xA882fB71aA1967A6a8aa11375F8abd5FeE5BDD6a",
"0xca4ddb84eceb805e39d7a1d0912e37765f0dc2e0",
"0x960C4CBA0a9dCBa913F52053cB487DC76C756165",
"0xf6745925f6591f6011109640500e6e928444f3dc",
"0xdb27d37572ea22a7c2b82d15101d6cabdb0b6f74",
"0x08c1b1cdcbd2f21c7b87dae2aab1fec626042ea7",
"0x29dc0ac648bbaa2333b310e9e567b2b9e2818b4c",
"0x12598F4b5F9C86056568222f51e4CC59429E5466",
"0x671c000aa98667506a2232737b3349c17ab82f60",
"0xa14af8a15b5ad7bc1fa8cb23076db8375c17f9a2",
"0x7724F320d432fa18CdDCDDf9A46AaE0Dbeb7622e",
"0x5bbd4cd56ae0da6b623d7e9345aa91e287f6eecf",
"0x61eC74876E545535Dd68663DE3654Ee6BA3706af",
"0x8D1bab837081EFcfE3469C8f99A334Fb0FE69cC9",
"0x04b66b7249097aeb308bc25353d126b21e9cbfd4",
"0x4919605b857926734476cb53add1ce0e1c8b7763",
"0xfaf7e95e4c61e41705c03637c17cd08b43bff338",
"0xcAc2ACd552ddC1b70568f9C88c61D02F94e9A57A",
"0xd2b170bd34e2e9182b5b592056ca611f8e76eb78",
"0x3Aec15f2247Db79b66d1Bb411cdf12A95706A78c",
"0x8e6Ab4127b3373377167e76cf1960818201B0200",
"0xef3da44b92e6ce57d35646fc4b147acc187c125f",
"0x113607267004cc98502E06A3c3abd48f5c5Fe645",
"0x145E9e42FBf7dcEed2DCFaB59C3312b9A2Aa164F",
"0xa6398dede419fddedaf8d123e1a652aa0aac2780",
"0xc1640a2adf233e196f58aeeee22f992763ef2ada",
"0x80ae87eff4614622518a82ff933861ab9000d1ac",
"0xb4FbCF067C499731d9E67f7E894451868cBFDe98",
"0xCA48004c5cd2575916382E4b3fb0888b6B93Bc01",
"0xb3fe55d999c86d4ed45c6f4e382b7523f16199cd",
"0x141c663fD81914A3AD328bce16a1b817A7bD82F2",
"0x45b1340c68925f0e5e789b281247a0c3939aeddf",
"0x521084c233fb5103634b706bd8fb1dfad4d4f3c2",
"0x8b20625537225F2e7f3df9A1183F57FC12596c00",
"0x0f4b785e8e2e38cb34e8bc52338f26ec6a88b4fd",
"0x603d04cA661Dadb4Fe68558D0B5B02Da58AA2B48",
"0x30f1BA8EAcD9746f070a067E122914beC854C756",
"0x87e916ccfbd95088501d7425c584f20d88f3784b",
"0xCF32DaEbf35Ec283de907d77f4ACdC177a14E557",
"0x6223a7c6ba9036e7f574371ae50a2a7620aa1c0b",
"0x2D5755AE076fDd6728FAbd5a6ccB6e20f6bF81EB",
"0xE0Eba903a56C280c5DD651d1c7f10d26Add63c87",
"0xd601d9337bd714677580fc8907a5fe6636dc2c7c",
"0x3f1eec07438d8266dd5198e5a3501a7d0a6156f4",
"0x1770c692db5f54a642f7f0b96541e92f37fd7454",
"0x047AA61fB65Df27c219d67025DdAE0683CecC37D",
"0x24a7c7d06357c790fbc13b8c95fd0dda582566b1",
"0x5788858ED0aD70DEA5056738b7FfD1810EacB84F",
"0xc2b2276c1c4282a597e010a7e386821d77c668ae",
"0x6b8102aab4199b14268f84c43af106ca3c6fa880",
"0x0cE97Fb914bd954e1Fd7b15621F50eE03Cec723b",
"0xa658a542348a3366ba67715e3f5e7e4fbab29815",
"0x6D2484B9c9E5961E90774ff0A6D51278Bd6031a8",
"0x774cE3d524d40Ed46da92D4fEa178821Ec474862",
"0x0be674807bDa89a85120c75B28Ba440296c0cFCF",
"0x05b7902b9477a2985691bfd431057016f54b80d0",
"0x49fbb9f90e78dd1c0569816e1eeeaf2c28414ed7",
"0x2c730a89bf97731090035ba7045e72d19a910a6a",
"0x1ef62c34fecbb252f9628bb9d7373ff56753eea6",
"0xD6d7556f615cBCD329747e7B6a0B3B60045A0004",
"0x4f64f72bf580b150f675859ec0ee4ac5458c0e18",
"0xf928edae51f0110c390346f1c50c5586bfe24746",
"0x4d46fb7c88f521b747e377b9ea466e0cf6f32f66",
"0x2050a6cc3dd1f9cafdd77bf3e49c5d3bf2c3084b",
"0x2A8F7F1682B629b16f5309182DA8920dAF72D0F9",
"0x2c0dfa7c96673ea2eee1744db93f81b09ceec4c5",
"0xf2A5d9C71dDc45Ca98476d39F3dfC2f16DCCaB68",
"0x0045CB2b22bc2615A09a90bDA866fF272c893906",
"0x53F2cABe26b9e35F082b3261F4D8989D27f118F3",
"0x6C3C511a3e6E0236926d9C0Ca622179E675c3692",
"0x08132517586f388f8B99D842cC5B3FeAeC776836",
"0xc600921a1c57b12a03c580959c67e7f1eb5727ee",
"0xda0c9a7867b35847cf4fc07ab156a5ad08486315",
"0x74fa1bE357bC182c88BA77E771a5502e2b271F1B",
"0xd5bc65ED8FEF954D959F7f32c97a6Da7A046f9D7",
"0xCd6f2B204A4ae2002A6B02722029177b41D92fE3",
"0x6dee5436c908439A7E961C715d83EFa640Fa545b",
"0xEFec274B641210fFC47BcE55F313F016804BCC66",
"0x6eDFA7FF8F447a26043D12baC446FCA895Da8A6E",
"0xF55Dbe8D71Df9Bbf5841052C75c6Ea9eA717fc6d",
"0x52dB08023bD549A6D208dB4B9322e8b0D581C1B1",
"0x072453c55D97eb46723ed989b435217939Dd7cB0",
"0x147168Fb0AD7765233f0A36Fa5A3F3075453af35",
"0x8deF36bA09af68cec83f89dE7A16a6300fd2074d",
"0x432426dE3bCBfb8deE4d9334861C3Eae5651C751",
"0x1C0a73429A98E793E448dc80c44eDaE80F21124F",
"0x551761574403ef21b14d398704Aa4FC0119e3a5c",
"0xb3B8D08c3C4266Bd0455AE6F7Fed5930d912DbaD",
"0xf755847e7170b09B655654d2328E2231b2E7D5D3",
"0x6a93Cb1807155b07720ABb80527647eec777703D",
"0xa3D961697c59817ce7CfFCCd8794d91b236666B8",
"0xf141C0C2A5EFf6Dcc6Cf914E0F183806469CF30E",
"0x501C1763e301b52927AF11d948C17437c55db0E0",
"0x34ea761752eADD9c80206C38bfd6F4c741A31Cb0",
"0xD9Bc1fC51b8D8369980505aa96e975DA03346B4A",
"0xf1c5a4e7cDd9e9AAe34B559dd5081566a1940e82",
"0x1545178e33b370F92dEE58e011F51D561cE22aF2",
"0x4be54BA3571438F94Eab4F7dAaC932230349EE7a",
"0x789e451d3f7Ea7367f5A4D8D10413abc36316740",
"0x6cCb6EDf790dc487531bB0D481ED6Dc672aea35c",
"0xcD58cD18BA65B92F93fDD0a7653D3f01F633E21f",
"0xCF0AD091af70E5652d2cf341aca547C24E8a1C7c",
"0x50E393Ac5C676b0F1b1F037D509Cc09053a352f8",
"0x06f0Ed6a3b3e0a2F4BC40aCe52f4FfCE6923E3E9",
"0x2153a907bE60C3550fC87982F84F654be642f907",
"0xEB9DBD0E7a365AfD1a6E9878ca7bc08aa63bae40",
"0x745c559D8aa36f1338cED7039523A4AC31a6Fa8c",
"0x8FBD2413805Dc70989d3E9C19493d8BB014e1b49",
"0xdfBa9ecb70041F4fE4Ec180EC26247449C01b5C0",
"0x34a444D9Be16b293c71195aeA38F599a49f3BEf0",
"0x963d55554877EA9d949BB9D0bB7fcABa113eb6Cc",
"0xE2FBa61aa4751D994994cdf41C719C71d16b059d",
"0x678fCf14d5f5E0f84227B992A7c84559Bf15ED9A",
"0x42D297c953B7B045C1288322fc81B13A217133c7",
"0x5ba65990cf61a963059af3d6f193D967e9343BE0",
"0x2275B8be039e99F88835CE49B16285aD0e61d485",
"0xa873949C3c94DB1dDF128b1867B49b0d75F987b4",
"0x2f623b63EC0B567533034EDEC3d07837cFCC9feE",
"0x6fA1634B284293dD4e74F4E3BD82239734DEfa42",
"0xC89A1c8f4adEA930706AD13B37592Da5fB205FFe",
"0xAe5Ed074a5727Ae41738C0482eD68719ad0baCe4",
"0x664F3ff2ecb037E55Aa340AaA429d5A3A8E8d437",
"0xE7CCfF5De5D5bbc638394D917EBFA6a60Cf38C08",
"0xF22735ae6261FC83904aEda37176b70c7C4434dD",
"0x2E6DE1dFebFfE1D5123e5AB342A6003f40ce71C1",
"0x843A46D1405F22903Dd1cD1aD80863dCA236bACF",
"0x44DDCC5214079Bd7F415538Ce7EBECb5cA630549",
"0x7Eca5a66eF9B5119D442E3A18F29B0Ea4104eBc7",
"0x95a86e614904e3f55b8B5FB1dedbcc076A058195",
"0x9F0062EA589C2BED6F2f6c01a0a1F3CBFe796A26",
"0x7B84e57B94f94D9DE4782bE5149B49Fe10fcc376",
"0xd237557b626F22647943008d02B6E1c01A95565c",
"0x8C470b23ec970Ac51D5A1a5fC510E583f1Eba98F",
"0x88c53C9ab000634c6662Ed9eceA6A33e0D783e1C",
"0xC5c1d363B4c2d62024A5494232ff23F22EfE6ed0",
"0xE1efd6A05D53b1875C279d01cA5a9ed5D1AbDfEa",
"0x5639e5aFac316a3386f53dDBCB3f9F52dE1A9b2A",
"0x20ca8E6a16e113392f3e2Cc6b05F57fcf2aBA323",
"0xDEFC4A8C554966Cb799D0E50CF94493A174a77dD",
"0x22cEd3f2b88916f9Be1262C25F514490589dc252",
"0x8EA5d42b5bEfc4DF7447e647B60D2396E93A4999",
"0x36D88d1187Cbdc924E4BdB8eDf9C827083C92393",
"0x89Cd4A5d21eF009AACcFEE6A769075F1dd42d0e0",
"0x4d5B21390d16329fC52f50CbA0BDAEdCDF879C9C",
"0x8Cb050C74FaAfee13Af654F2A6AE4690Df17a098",
"0x3434D4695768F61C692a9fB53E15915581f23370",
"0x1B76EBA3c3223f03a8E519602A51B862F16996ae",
"0x77F00a4676844AF2C576aB240a423DCd81664c8E",
"0xd49214b455698258B5995416B3e6a5c8ffabFcd8",
"0xBc0b3fcCF30DE98E88871094af29caEB1e3329F2",
"0xCD244fD76374a069a4De471706890b6Aae554B1c",
"0xd54307c45336b2f1C4C28c070C5ac7762085B720",
"0x45552e83823c69B56E71a3dA8C7249c9Fea0999E",
"0x098774c89B27de3617220Abf060FA91eE2ee1C85",
"0x7F7b7a13c9d1A7E695e6d19cA84c851c262fE91B",
"0x79DA34B293df8bA3DB9b8DA65805B376055EcAD3",
"0x58760BC194B89Ed4efB7E10EaFD2E17BFc4C8B8C",
"0xCc48857242141CC7F4f8555f8a3d5bED03556C19",
"0x774cA00554e989263f20613242951D05A548C21E",
"0xbb45783ECf26d526826E5bfF99858eB075683c2A",
"0x4a87D1b71937F06Db976631BdFacC82A5cdd076A",
"0xf82cc9a8491ba745eA8f9F43dC6cF3714664a227",
"0x04D8C56eF4995e12f2502b921024e2CE0596C0E8",
"0xa5BE60342CAe79d55ae49d3a741257a9328a17EA",
"0x3f1F3E6e454A2132F976065c744F3fc5337E8ab0",
"0x751BE7D570C07aca2F42B04F4302e548d33244ca",
"0x04142a0c07CEA15d03A82EC0DCf31DadB6179FC5",
"0x062ACC6b1f60b85E52Ce91cc17aeFD6f82f0E7AB",
"0xD48B29E527E7C2D3Ca2A1321AC1d1239C49D5749",
"0x93DCAc4ce35FC7266c3e8f769F8cb7BeAb1865a8",
"0x19919939c1b6A66696dB0814E86e2b478Df938ee",
"0x1a7f44e14b2644Ec12b2D27efAD6710B580371f9",
"0x906deA1c63c0dDBbA167d3A21E9Cdc1eb24B6036",
"0x6EB1272c00Aabe7e25E7107E42ec94d6Dd657238",
"0x7Dd03A6634D3Ca0F1B7D7B46f0bE62A142e25E9F",
"0xDBbce16eDeE36909115d374a886aE0cD6be56EB6",
"0xA27E9a2dc7f8Bb50e5eF1F29CA1575dc2E97CfC5",
"0x52b513E6E95A8A451C82ED668d1525c4ae32B246",
"0x4d11ac0EFdAaf80758528dc2f3eA8a29A03c65a5",
"0x331254b4b39847738C6367e7559607cA7f279CB0",
"0x0DD80203907596C1F93EA4af13E4DdC6D2103A9A",
"0xD096F2933980F682FA099Cd1Ac5F604D2667c6a9",
"0xe20540fF405A81023C664486C09D7Be0Df3d1f2F",
"0x5c48B0f86260061783ec11806F893b2589D50fCa",
"0x7DF437C26813E2d976d2D88fc99E361F9E90AD3c",
"0x0BE515Bcf6aad05F58675099484577620AE33D45",
"0xBbC4b18ba8557bcD5327D28509663b2f3546f3E3",
"0x8aD936799DBBea0E1584C70a1D05D786e3B7D882",
"0x79Cae7d47D37AEcab146a1aefC5BDf9F6EEfc974",
"0x6C7008dadC61225CD82B4d62519b97fdB547f290",
"0xDf0c58b78aa30F906f59599352B8Aa4f92520beb",
"0xed9a750995A84Ba18A4A6a319862c4202d428fEA",
"0x42259a5f9E69E7BB7567b269657b5C0BaAac7895",
"0x2634c47c459A42475b2acA1243b25aEe8a056c5f",
"0xDf2b3d6B9654A140fe7fC97265E578DCDF8c1737",
"0xDb57D06c4b9414a79dd194A9bFaB95F10911917C",
"0x334c84f0Dae9FeE4A13313A5e6282Ccca0E0B792",
"0x43f5BfaF9FdcBB59e13f20A7664EA94bCB0FA577",
"0x45b5612eF785a5F01dcf102b300a3f80A4131C5F",
"0xFcc2e892bb08e640bC420e26a5018417102AC807",
"0x8df43d30e120740f63fb34025cf94a4adac85644",
"0xfE20804D63366242b3BB8b421D938AA274A362E9",
"0x252aD4c147630634170971fE0BEe72FeaF7DfCb3",
"0x97cb91642902ef48709bEcA1236813495FceEB71",
"0xFc0f2D02453336921d4f54dcEaf1fA5371986596",
"0xB00cdD1Ef43e7B7d2Fdad4a07e6A1Fd13382b90a",
"0x0D8F37352951BF6B6BC7Ac3FeAacfC25754aCB07",
"0xaF9dE23d8695F08B97c1720e3fc8D04423441B4b",
"0xb831dB626fa1b2af05bFAfE535614e4a74Ee2e23",
"0x8237F0cC8444419Bd03bacF4913B8af90eA02e6b",
"0x0553cEb8b241aE36AF788a603Cf6E5cf06d6D5A2",
"0x112630bA98300A8FB0aF4EdDB42449c03fC65a58",
"0xe6E416d39Dd0b521dA1b59D3af4D8930e6d5626B",
"0x5C9079C46E151164CaA3BF781D45f913c2bD06a9",
"0xC277B0dae54Bb0aA5cD87c5E62c078CC5199f54d",
"0x31E4022eE3fC9D3B43c1905664A58c98cc382a12",
"0xFC51089Adf2E5Ba5658f3E176c7cfFf4A58A0dfE",
"0x774024401e1d39F7322161632eF45b3B37AD6c66",
"0xb73bae22A8a02469b6fe11e653F9C06Ec46123a6",
"0x63A00135Ac2aA9168dE542110c88D165Deaef81D",
"0xEcA1d112f3eF0bc30289540b3663894313121dE0",
"0xd448905EF32b90C2d5BEE343b5c56b13CCDd6873",
"0xF73A7B68BBaD987273f437b8fC8ad5c270DB5d27",
"0x5CA795e1D4e8347240a1F157d8c17D0a81dDAAE0",
"0xF904A3932979cBf4EEaAB3b588396883ebC360bA",
"0x058FD36A48e1C9980B34b41eaC8a46C3EAF19A41",
"0x7D4E25d6bd1dF99135705F997E5E479E5090CA47",
"0x5C62830CBe0A79DC4d3e389e88d2D866339506F4",
"0x465E2a5228DDd5c1cA8e4EDfC8AB676b07e0Ed92",
"0xD34788e2F96758972C27678725808E50eF843f01",
"0xb3d52217Bc6988F39724b428B2A123D64b0682d4",
"0xeB40d183FAeC21Ca7aBc4AF1A5AFE59b1e887149",
"0x3600d3650FA9e706498eB74ba9Bf098f87d3A1Bf",
"0x408807d8566c05A3053F947E61610bdDd5286666",
"0x04a903B982242143C57Ed39F31eE0C2ed22e1a1a",
"0xF9bD5a333F8B30F4BEbFbA0faC7afB5BE07996f8",
"0xa23C3f27402C95Da1188400e46cf74b1Cd18caBC",
"0x8D08C764f9fEF34a212E7C52655458B505EF1Dd6",
"0x303a8925D1479A49212E5F7A77396F5Fab12db3f",
"0x360e3f47a62de5876b3f25ac29e947a9fec17bca",
"0x3E30Cd9FdB7081CA89F41837fcDa7dc88Dc20B7E",
"0xcCe416aD02f3B572A5132BCd8e29da1875B6D5B4",
"0x07b37D246E4D54d277A9Cb9d46e43888816DF308",
"0x6ce9d9e55129F9662cc47dA6763D20607E500c35",
"0xCbe80C1faF915Cde4C685B7116A68B47a25dA326",
"0x05db39098e1dF2a3b55d0fc89Ef4D0517483d3A6",
"0x394b0CA58672253287a2b4BB2EE8ae73D3bad4c2",
"0xfa79b0967Ecf65c00C7584d03af8d6910EC0b006",
"0x10E0746C30773e0FEFF9452bc652107dB5eD7c13",
"0x14333ba9791E7cd64b319964cf1Fd9901082887c",
"0x9E0154c935F8624B5FCE5577d4FBc3AF4aBa0eF7",
"0xfF095dc67945Ba4D7bc1ecdDA9b1Dd578d271b19",
"0xb1fa9f500c0BDEDb76A455577cfA71062eE02DA6",
"0x2DE4EFcCdCBb8b59223Fd5a4Ec17BFD0074974Dd",
"0x560f4D2b04E614bdAF7541580F0F6C0b20F54e95",
"0x45fB8Fd100a06e3701D41d79Cd5907C65E3A8f91",
"0xA227B5ef06410639D4985d6be693352B71b8A165",
"0xfb2d5e0c823a504798ebd009369c1994bef986af",
"0x50042aC52aE6143caCC0f900f5959c4B69eF1963",
"0xa2d5ea5d4f7e3e85ee13061e716bd7bd58dc2076",
"0xe67ecc40db593c4711fef02cf2e5f046e91a0131",
"0x16c24b1e6Aaf45DbC086f3398eCa0313Edc6F153",
"0x497c2121825910D239b863efD57F981B252BD5F5",
"0xc09956866623Ac5c49cb543634b0a38a6Eeaa862",
"0xA97556944Cd74B913821bFF1732315a619d608F4",
"0x25a4e9ea4AD84FD357052a7732E0379c2b78DcB6",
"0x3Fbe1DeD0aBf17231049D1739B3FE1F892ab08C6",
"0xe47B6Bc8F09f000f14b2A00D5814752526358e0e",
"0x46E1565e34f4f6039ebc12CEF94f716a54aCF5bF",
"0x960042BB9CdeD062086D8a4F3b501fe613139EaA",
"0x474547792CbC3E68C05A72BFba702E1254B65fb6",
"0xaB29fABdCbfAAD51C51F626F59fca57DBA8Eb5E8",
"0x9Df58bdf4980FAaA1D2D28818e88647f93BD98d2",
"0xA2536109acf420225026D35972a3b5481348E602",
"0x04a903B982242143C57Ed39F31eE0C2ed22e1a1a",
"0x20d443723aD7F6BA952275C46d82EEAE0fCd1398",
"0x8597550e1d61858400DCdC8EcF9E0fFF410926C4",
"0xc3849287dc3fC6bA3B9F399717430DA55f0cFa02",
"0x411B05448Df50d9953873f24d97A77B8EEC4Ccd8",
"0xb976DfeDE0be9aE7af7cbd99254dd093F0a3E50F",
"0xc2DEC767560FC33B378324E79B991226712A3374",
"0x7754a6903fFD61034E4EEa39b2562bdf7D5926FB",
"0x7EBC158be645c9e99163F676f09b3476F188bb2f",
"0x3bFb5E6d0b9E39d4B3B2a79725A047E6874f822d",
"0x4ce13b1C4E6Cc71a35cbce8d2e8BdB9eD9f3099F",
"0x69C83eE3c31524EB367DC04D0931A3dBF0E91C1b",
"0x473057666D96Ad170C7c9a895f11B444be39028d",
"0x0efB03f72c8Eb8214315d44671Bf578331150A29",
"0x1cA8ba8a8Fbc9Df50a5116b7eac81a0c6a5e7D7C",
"0x537092aA950bBbFE4fB2333CEf7F6bf06976ac58",
"0x3f9A0aadC7F04A7c9d75dc1b5a6DdD6E36486CF6",
"0xC274033e9e8b6Ec96182e9aB6dEe61dEC26A95Ce",
"0x7f2f0b4aC8D68E846E5Fd000fE25A078453C5B68",
"0x914a68247DaE6ce4C0d869194fB7b2258af5075D",
"0xc6eAAcAa6De1b7C98Ff558Da46E0Bbd62a333885",
"0xE0f17d4646fEFc3D77EC1Be2C3736151D3897F1D",
"0xa0e75991a1495b22C4ff51812f55070BFca276c1",
"0x507A306e56c05bC2b63366F1668595458235EA30",
"0x9f4871E648F331D5BC3c65918b61cA2c5A85c68e",
"0x6DEf319710c96F01092FbFdD680d18094F1F2D5f",
"0x3421fbacd45c4df3f08ef88a77343db47f130808",
"0xa652d02215D9464F629A63eDCECaa6595af1534E",
"0x13024b1269cc42e0f58AA1Ba2088188d893B76b0",
"0xF2F3CF7Aa50070Dc55e3b4471E7136aD194945df",
"0xDA63Dc81c6041165763b25B959AeB76687E36Da7",
"0x8188e3E8F1Dd0E20A785Bb0C4b9E42C9c6499653",
"0xD48faa016B3087C9A18Cb403755E6Ce964764317",
"0x7b463B93C5c60067628541ae7603449CB8e662dc",
"0x498022A0dfc236956E8A4a2052e47fEE9b93489f"

    
  ].map((a) => toChecksumAddress(a));

  return new MerkleTree(elements, hash, {
    hashLeaves: true,
    sortPairs: true,
  });
};
