import React from 'react';
// import { Dapp3 } from './components/Dapp3/Dapp3';
import { Dapp1 } from './components/Dapp1/Dapp1';
// import { Dapp2 } from './components/Dapp2/Dapp2';
// import EntryPage from "./components/EntryPage/EntryPage";

function App() {
  return (
    <React.Fragment>
      <Dapp1 />
      {/* <Dapp2 /> */}
      {/* <Dapp3 /> */}
      {/* <EntryPage /> */}
    </React.Fragment>
  );
}

export default App;
